const initialState = {
    masterSettingsList: [],
    masterStateLists: [],
    masterCityStateWiseLists:[],
    masterUsertypeLists:[],
    masterUserLists:[],
    masterInsuranceList:[],
    masterInsuranceManagerWiseList:[],
    masterDesignationList:[],
    masterHospitalList:[],
    masterRelationshipList:[],
    masterOccupationList:[],
    masterLocalityList:[],
    masterEducationalList:[],
    masterJobList:[],
    masterRoomCategoryList:[],
    masterIncomeDetailsList:[],
    masterInjuryTypeList:[],
    masterClaimTypeList:[],
    masterCheckList:[],
    masterAllCityList:[],
    masterGeofenceList:[],
    loadingMaster: false,
    errorsMaster: {}
}

const  masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MASTER_SETTINGS_PENDING":
        case "GET_MASTER_STATE_PENDING":
        case "GET_MASTER_CITY_STATE_WISE_PENDING":
        case "GET_MASTER_USERTYPE_USER_WISE_PENDING":
        case "GET_MASTER_USER_USERTYPE_WISE_PENDING":
        case "GET_MASTER_INSURANCE_PENDING":
        case "GET_MASTER_INSURANCE_MANAGER_WISE_PENDING":
        case "GET_MASTER_DESIGNATION_PENDING":
        case "GET_MASTER_HOSPITAL_PENDING":
        case "GET_MASTER_RELATIONSHIP_PENDING":
        case "GET_MASTER_OCCUPATION_PENDING":
        case "GET_MASTER_LOCALITY_PENDING":
        case "GET_MASTER_EDUCATION_PENDING":
        case "GET_MASTER_JOB_PENDING":
        case "GET_MASTER_ROOM_CATEGORY_PENDING":
        case "GET_MASTER_INCOME_PENDING":
        case "GET_MASTER_INJURY_TYPE_PENDING":
        case "GET_MASTER_CLAIM_TYPE_PENDING":
        case "GET_MASTER_CHECKLIST_PENDING":
        case "GET_MASTER_ALL_CITY_PENDING":
        case "GET_MASTER_GEOFENCE_PENDING":
            return { ...state, loadingMaster: true };

        case "GET_MASTER_SETTINGS_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterSettingsList: action.payload,
            };

        case "GET_MASTER_STATE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterStateLists: action.payload
        };

        case "GET_MASTER_CITY_STATE_WISE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterCityStateWiseLists: action.payload
            };

        case "GET_MASTER_USERTYPE_USER_WISE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterUsertypeLists: action.payload
            };

        case "GET_MASTER_USER_USERTYPE_WISE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterUserLists: action.payload
            };

        case "GET_MASTER_INSURANCE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterInsuranceList: action.payload
            };

        case "GET_MASTER_INSURANCE_MANAGER_WISE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterInsuranceManagerWiseList: action.payload
            };

        case "GET_MASTER_DESIGNATION_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterDesignationList: action.payload
            };

        case "GET_MASTER_HOSPITAL_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterHospitalList: action.payload
            };

        case "GET_MASTER_RELATIONSHIP_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterRelationshipList: action.payload
            };

        case "GET_MASTER_OCCUPATION_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterOccupationList: action.payload
            };

        case "GET_MASTER_LOCALITY_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterLocalityList: action.payload
            };

        case "GET_MASTER_EDUCATION_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterEducationalList: action.payload
            };

        case "GET_MASTER_JOB_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterJobList: action.payload
            };

        case "GET_MASTER_ROOM_CATEGORY_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterRoomCategoryList: action.payload
            };

        case "GET_MASTER_INCOME_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterIncomeDetailsList: action.payload
            };

        case "GET_MASTER_INJURY_TYPE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterInjuryTypeList: action.payload
            };

        case "GET_MASTER_CLAIM_TYPE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterClaimTypeList: action.payload
            };

        case "GET_MASTER_CHECKLIST_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterCheckList: action.payload
            };

        case "GET_MASTER_ALL_CITY_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterAllCityList: action.payload
            };

        case "GET_MASTER_GEOFENCE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterGeofenceList: action.payload
            };

        case "RESET_MASTER_STATUS":
            return {
                ...state,
                loadingMaster: false,
                errors:{}
            };

        case "GET_MASTER_SETTINGS_REJECTED":
        case "GET_MASTER_STATE_REJECTED":
        case "GET_MASTER_CITY_STATE_WISE_REJECTED":
        case "GET_MASTER_USERTYPE_USER_WISE_REJECTED":
        case "GET_MASTER_INSURANCE_REJECTED":
        case "GET_MASTER_INSURANCE_MANAGER_WISE_REJECTED":
        case "GET_MASTER_DESIGNATION_REJECTED":
        case "GET_MASTER_HOSPITAL_REJECTED":
        case "GET_MASTER_CLAIM_TYPE_REJECTED":
        case "GET_MASTER_CHECKLIST_REJECTED":
        case "GET_MASTER_ALL_CITY_REJECTED":
        case "GET_MASTER_USER_USERTYPE_WISE_REJECTED":
        case "GET_MASTER_GEOFENCE_REJECTED":
            return {
                ...state,
                loadingMaster: false,
                errorsMaster: action.payload
            };

        default:
            return state;
    }
}

export default masterReducer;