import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Badge } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { deleteGeofenceData, getGeofenceList, updateGeofenceStatusData } from "../../redux/actions/GeofenceActions";
import RoundedPagination from "../../Components/Paginate";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../util/generalUtills";
import Preloader from "../../Components/Preloader";



const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    geofenceReducer: {
      geofencePermissions,
      geofenceLists,
      loadingGeofence,
      errorsGeofence,
      geofenceCount,
      changedGeofenceStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  
  // useEffect(() => {
  //   dispatch(getGeofenceList(auth?.token, paginate, page, search, sort));
  //   console.log('geofenceLists',geofenceLists);
  // }, [auth, paginate, page, search, sort]);

  const fetchGeofenceList = useCallback(() => {
    if (auth?.token) {
      dispatch(getGeofenceList(auth.token, paginate, page, search, sort));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchGeofenceList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])



  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? geofenceCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1); // Reset to the first page whenever the paginate value changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteGeofenceData(auth?.token,id))
      }
    });
  };

  const handleStatusChangeRequest = (id, newStatus) => {
    swal.fire({
      title: "Are you sure?",
      text: `Do you want to change the status to ${newStatus}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateGeofenceStatusData(auth.token, id, newStatus));
      }
    });
  };


  useEffect(() => {
    //console.log(changedGeofenceStatus);
    if(changedGeofenceStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changedGeofenceStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_GEOFENCES_STATUS' });
      dispatch(getGeofenceList(auth?.token, paginate, page, search, sort));
    }
    else if(changedGeofenceStatus.success)
    {
      swal.fire({
        show: true,
        title: "Great !",
        text: changedGeofenceStatus.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_GEOFENCES_STATUS' });
      dispatch(getGeofenceList(auth?.token, paginate, page, search, sort));
    }
  },[changedGeofenceStatus])



  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Geofence"), path: "/Geofence", active: true },
        ]}
        title={t("Geofence")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <div className="d-flex align-items-center">
                      {geofencePermissions?.filter && (<>
                        <input
                          type="search"
                          placeholder="Search..."
                          className="form-control ms-1"
                          autoComplete="off"
                          value={search}
                          onChange={handleSearchChange}
                        />
                      </>)}
                      
                    </div>
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                        {geofencePermissions.add && (
                          <>
                            <Link to="/Add-Geofence">
                              <Button variant="success">
                                <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                              </Button>
                            </Link>
                          </>
                        )}
                        {geofencePermissions.print && (<>
                          <Button variant="dark">
                            <i className="mdi mdi-printer me-1"></i>Print
                          </Button>
                        </>)}
                        {geofencePermissions.excel && (<>
                          <Button variant="info">
                            <i className="mdi mdi-file-excel me-1"></i>Excel
                          </Button>
                        </>)}
                        
                        
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
                <Table className="mb-0 table-bordered"
                  size="sm"
                  striped
                  style={{ width: "100%", tableLayout: "fixed" }}>
                  <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Status</th>
                      <th>Registered At</th>
                      {(geofencePermissions.edit || geofencePermissions.delete) && (<>
                        <th>Action</th>
                      </>)}
                      
                    </tr>
                  </thead>
                  <tbody>
                  {geofenceLists.length === 0 ? (
                    <tr>
                      <td colSpan="6">No data found</td>
                    </tr>
                  ) : (<>
                    {geofenceLists?.map?.((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.name}</td>
                          <td>{item?.address}</td>
                          <td>{item?.status === "Active" ? <Badge className={"bg-success"}>{item?.status}</Badge> : <><Badge className={"bg-danger"}>{item?.status}</Badge></>}</td>
                          <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                          {(geofencePermissions.edit || geofencePermissions.delete) && (<>
                            <td>
                            {(geofencePermissions.edit) && (<>
                              {item?.status === "Active" && (
                                <>
                                  <Link to="#" className="action-icon text-success" onClick={() => handleStatusChangeRequest(item?.id , "In-Active")}>
                                    {" "}
                                    <i className="mdi mdi-eye"></i>
                                  </Link>
                                </>
                              )}
                              {item?.status === "In-Active" && (
                                <>
                                  <Link to="#" className="action-icon text-warning" onClick={() => handleStatusChangeRequest(item?.id, "Active")}>
                                    {" "}
                                    <i className="mdi mdi-eye-off"></i>
                                  </Link>
                                </>
                              )}

                              <Link to={`/Update-Geofence/${item?.id}`} className="action-icon text-info"> 
                                <i className="mdi mdi-square-edit-outline"></i>
                              </Link>
                            </>)}
                            {(geofencePermissions.edit) && (<>
                              <Link to="#" className="action-icon text-danger" onClick={() => handleDeleteRequest(item?.id)} >
                                {" "}
                                <i className="mdi mdi-delete"></i>
                              </Link>
                            </>)}
                              

                              

                              
                            </td>
                          </>)}
                        </tr>
                      );
                    })}</>
                  )}
                  </tbody>
                </Table>
              </div>
              <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(geofenceCount / paginate)}</strong> </span>
                <RoundedPagination currentPage={page} totalPages={Math.ceil(geofenceCount / paginate)} onPageChange={(pageNumber) => setPage(pageNumber)}/>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingGeofence && (<Preloader/>)}
    </>
  );
};

export default Dashboard;
