import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spinner, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getSubCheckListById, saveSubCheckData, updateSubCheckData } from "../../redux/actions/SubChecklistActions";
import FormInput from "../FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCheckList } from "../../redux/actions/MasterActions";
import Preloader from "../Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddSubChecklist = ({ show, onHide, editId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    subChecklistReducer: {
      loadingSubChecklist,
      errorsSubChecklist,
      subChecklistLists,
      changedSubChecklistStatus,
    },
    masterReducer: {
      loadingMaster,
      errorsMaster,
      masterCheckList,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getAllCheckList(auth.token));
    }
  }, [auth?.token]);

  useEffect(() => {
    setFormData({})
    if(editId) {
      dispatch(getSubCheckListById(auth?.token, editId));
    }
  }, [auth, editId]);

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(editId)
    {
      dispatch(updateSubCheckData(auth?.token, formData,editId));
    }
    else
    {
      dispatch(saveSubCheckData(auth?.token, formData));
    }
  };

  useEffect(()=>{
    if(editId && subChecklistLists && Object.keys(subChecklistLists).length > 0) {
      setFormData({
        checklist_id: subChecklistLists?.checklist_subchecklist?.id,
        name: subChecklistLists?.name,
      });
    }
  },[subChecklistLists])

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);

  useEffect(() => {
    if (errorsMaster && Object.keys(errorsMaster).length > 0) {
      if(errorsMaster?.status==401){
        dispatch(logout())
        toast.error(errors?.data?.message);
        onHide();
      }
    }
  }, [errorsMaster]);

  useEffect(() => {
    if (changedSubChecklistStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedSubChecklistStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_SUBCHECKLIST_STATUS' });
    }
  }, [changedSubChecklistStatus, dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const selectHandler = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };


  return (
    <Modal show={show} onHide={onHide} size="md" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{(editId && subChecklistLists && Object.keys(subChecklistLists).length > 0) ? t("Update Sub Checklist") : t("Add Sub Checklist")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            {errorsSubChecklist?.data?.message && (
              <div className="alert alert-danger">
                {errorsSubChecklist?.data?.message}
              </div>
            )}
          <Row>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Checklist")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                <Select
                  options={masterCheckList}
                  values={masterCheckList.filter((qr) => qr.id === formData.checklist_id)}
                  name="checklist_id"
                  labelField={"name"}
                  valueField={"id"}
                  loading={loadingMaster}
                  onChange={(selectedData) => selectHandler(selectedData[0]?.id, "checklist_id")}
                  styles={customSelectStyles}
                />
                
                {errorsSubChecklist?.data?.checklist_id && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsSubChecklist?.data?.checklist_id}</p>
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col lg={12}>
              <FormInput
                label={t("Sub Checklist Name")}
                type="text"
                errors={errorsSubChecklist?.data}
                value={formData.name}
                name="name"
                required={true}
                onChange={inputHandler}
                containerClass={"mb-3"}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            {loading ? 
              <Button color="primary" disabled>
                <Spinner animation="border" size="sm" />
                {t("Please wait ...")}
              </Button>
            :
              <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
              {(editId && subChecklistLists && Object.keys(subChecklistLists).length > 0) ? t("Update") : t("Add")}
              </Button>
            }
          </div>
        
      </Modal.Body>
      {loadingSubChecklist && <Preloader />}
    </Modal>
  );
};

export default AddSubChecklist;
